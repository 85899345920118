import React from 'react';
import { TopcoLink } from '@src/view/common/link';
import cn from 'clsx';

const Custom404 = () => {
  return (
    <section
      className={cn(
        'mainCon flex flex-col items-center justify-center min-h-inherit',
      )}
    >
      <div className="imgCon w-[80vw] max-w-[613px]">
        <img src="/images/error/404/img_not_found_main.png" alt="" />
      </div>
      <div className="athorContentCon flex flex-col gap-y-[18px] mo:gap-y-[12px]">
        <p className="text-[#BD8066] text-center font-[Poppins] text-[1.2rem] font-medium underMo:text-[1rem]">
          Something went wrong...
        </p>
        <TopcoLink.Normal
          href="/"
          title="BACK to HOME"
          classNames={cn(
            'rounded-[50px] mx-auto text-[#FFEAC3] text-[20px] w-full font-semibold px-[5px] max-w-[245px] bg-[#BD8066] flex items-center justify-center h-[50px]',
            'mo:text-[16px] mo:max-w-[216px] mo:h-[44px]',
          )}
        />
      </div>
    </section>
  );
};

export default Custom404;
